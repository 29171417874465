import { Injectable } from '@angular/core';

/**
 * Odpovida za informace ohledne bezicich http pozadavku.
 */
@Injectable({ providedIn: 'root' })
export class LoadingIndicatorService {
  private requestsCount = 0;

  requestStarted(): void {
    this.requestsCount++;
  }

  requestFinished(): void {
    if (this.requestsCount > 0) {
      this.requestsCount--;
    }
  }

  isLoading(): boolean {
    return this.requestsCount > 0;
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { InitializeApp } from '@core/states/app/app.action';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppInitService {

  constructor(private store: Store) {
  }

  /**
   * Initializes application - fetches all relevant information that application needs in order to run.
   */
  async initialize(): Promise<void> {
    return lastValueFrom(this.store.dispatch(new InitializeApp()));
  }
}

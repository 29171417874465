import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { ScIconModule } from '@store-components/icons';
import { NgxsModule } from '@ngxs/store';
import { environment } from '@env/environment';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { AppInitService } from '@core/services/app-init/app-init.service';
import { AppState } from '@core/states/app/app.state';
import { LoadingIndicatorInterceptor } from '@core/interceptors/loading-indicator/loading-indicator';
import { HttpXsrfInterceptor } from '@core/interceptors/security/http-xsrf.interceptor';
import { ScMobileModule } from '@store-components/mobile';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF } from '@angular/common';

export const initializeApp = (appInitService: AppInitService): any =>
  async (): Promise<void> => appInitService.initialize();

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    ScMobileModule,
    ScIconModule,
    NgxsModule.forRoot([AppState],
      { developmentMode: !environment.production }),
    NgxsRouterPluginModule.forRoot(),
    environment.ngxsPlugins
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/lom'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingIndicatorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpXsrfInterceptor,
      multi: true
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}

import { routeNamesType } from '@route-names';

export function parseLastRoute(fullRoute: string): routeNamesType {
  const splitPos = fullRoute.lastIndexOf('/');
  return splitPos !== -1 ? fullRoute.slice(splitPos + 1).split(/[?#]/)[0] as routeNamesType : null;
}

export const appLayout = 'appLayout';

export enum AppLayout {
  GRID = 'grid',
  ROWS = 'rows'
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ErrorHandlerService } from '@core/services/error-handler/error-handler.service';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { ON_BACKGROUND } from '@core/interceptors/loading-indicator/loading-indicator';

@Injectable({ providedIn: 'root' })
export class HttpClientService {

  constructor(private http: HttpClient,
              private errorHandlerService: ErrorHandlerService) {
  }

  postForResponse<T>(link: string, data: unknown, options?: HttpClientOptions): Observable<HttpResponse<T>> {
    const context = new HttpContext().set(ON_BACKGROUND, !!options?.onBackground);
    return this.setErrorHandler(this.http.post<T>(link, data, { context, observe: 'response' }), options);
  }

  postForData<T>(link: string, data: unknown, options?: HttpClientOptions): Observable<T> {
    const context = new HttpContext().set(ON_BACKGROUND, !!options?.onBackground);
    return this.setErrorHandler(this.http.post<T>(link, data, { context }), options);
  }

  get<T>(link: string, options?: HttpClientOptions): Observable<T> {
    const context = new HttpContext().set(ON_BACKGROUND, !!options?.onBackground);
    return this.setErrorHandler(this.http.get<T>(link, { params: options?.params, context }), options);
  }

  private setErrorHandler<T>(request: Observable<T>, options?: HttpClientOptions): Observable<T> {
    return request.pipe(catchError(e => {
      if (options?.useErrorHandler === false) {
        return throwError(() => e);
      }
      return this.errorHandlerService.handleError(e, options);
    }));
  }
}

export interface HttpClientOptions {
  form?: FormGroup | UntypedFormGroup;
  useErrorHandler?: boolean;
  params?: HttpParams;
  onBackground?: boolean;
}

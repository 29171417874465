import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { routeNames } from '@route-names';
import { Store } from '@ngxs/store';
import { Uzivatel } from '@partner/models/uzivatel';
import { AppState } from '@core/states/app/app.state';

/**
 * Resi presmerovani na zaklade toho jestli uzivatel je prihlasen nebo ne.
 */
export const isAuthorizedGuard: CanMatchFn = () => {
  if (inject(Store).selectSnapshot<Uzivatel>(AppState.getUzivatel)) return true;

  const router = inject(Router);
  const queryParams = router.getCurrentNavigation().initialUrl.queryParams;
  return router.navigate([`/${routeNames.BEZPECNOSTNI_ROZCESTNIK}`], { queryParams });
};

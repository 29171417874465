import { Component, OnDestroy } from '@angular/core';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { RouterDataResolved } from '@ngxs/router-plugin';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { ClearMessage } from '@core/states/app/app.action';
import { parseLastRoute } from '@shared/utils/global.util';

@Component({
  selector: 'lom-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(actions$: Actions,
              private store: Store,
              private router: Router) {
    actions$.pipe(
      ofActionDispatched(RouterDataResolved),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      const lastRoute = parseLastRoute(this.router.url);
      if (lastRoute) this.store.dispatch(new ClearMessage(lastRoute));
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { Injectable } from '@angular/core';
import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingIndicatorService } from '@core/services/loading-indicator/loading-indicator.service';

/**
 * Reaguje na odeslane http pozadavky, rika loading servise, kdy zpracovani pozadavku zacalo/skoncilo.
 */
@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {
  constructor(private loadingIndicatorService: LoadingIndicatorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(ON_BACKGROUND) === false) {
      this.loadingIndicatorService.requestStarted(); // Pro request zobrazujeme loading.
      return next.handle(req).pipe(
        finalize(() => {
          setTimeout(() => this.loadingIndicatorService.requestFinished());
        })
      );
    }

    return next.handle(req); // Pro request nezobrazujeme loading.
  }
}

export const ON_BACKGROUND = new HttpContextToken<boolean>(() => false);

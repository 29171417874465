import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { routeNames } from '@route-names';
import { Store } from '@ngxs/store';
import { Uzivatel } from '@partner/models/uzivatel';
import { AppState } from '@core/states/app/app.state';

/**
 * Resi presmerovani na zaklade toho jestli uzivatel je prihlasen nebo ne.
 */
export const redirectGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const uzivatel = inject(Store).selectSnapshot<Uzivatel>(AppState.getUzivatel);
  const router = inject(Router);
  router.navigate([
    uzivatel ? `/${routeNames.SPRAVA_UZIVATELE}` : `/${routeNames.BEZPECNOSTNI_ROZCESTNIK}`
  ], { queryParams: route.queryParams });
  return false;
};

import { Message } from './app.model';
import { routeNamesType } from '@route-names';

export class AddMessage {
  static readonly type = '[APP] AddMessage';

  constructor(public message: Message) {
  }
}

export class ClearMessage {
  static readonly type = '[APP] ClearMessage';

  constructor(public routeName: routeNamesType) {
  }
}

export class InitializeOdkazy {
  static readonly type = '[APP] InitializeOdkazy';
}

export class InitializeEnv {
  static readonly type = '[APP] InitializeEnv';
}

export class InitializeUzivatel {
  static readonly type = '[APP] InitializeUzivatel';
}

export class InitializeApp {
  static readonly type = '[APP] InitializeApp';
}

export class ResetUzivatel {
  static readonly type = '[APP] ResetUzivatel';
}

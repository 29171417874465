export const routeNames = {
  BEZPECNOSTNI_ROZCESTNIK: 'bezpecnostni-rozcestnik',
  BEZPECNOSTNI_METODY: 'bezpecnostni-metody',
  ZAPOMENUTE_HESLO: 'zapomenute-heslo',
  LOGOUT: 'logout',
  SPRAVA_UZIVATELE: 'sprava-uzivatele',
  ROZCESTNIK: 'rozcestnik',
  DETAIL_UZIVATELE: 'detail-uzivatele',
  ZMENA_HESLA: 'zmena-hesla',
  FAQ: 'faq',
  KONTAKTY: 'kontakty',
  ERROR: 'error',
} as const;

type key = keyof typeof routeNames;
export type routeNamesType = typeof routeNames[key];

import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AppModel, DoplnujiciNastaveniOdkazu, EnvConfig, ExtendedOdkaz } from '@core/states/app/app.model';
import {
  AddMessage,
  ClearMessage,
  InitializeApp,
  InitializeEnv,
  InitializeOdkazy,
  InitializeUzivatel,
  ResetUzivatel
} from '@core/states/app/app.action';
import { HttpClientService } from '@core/services/http-client/http-client.service';
import { firstValueFrom, forkJoin } from 'rxjs';
import { OdkazyResponse } from '@george/models/odkazy-response';
import { Uzivatel } from '@partner/models/uzivatel';
import { Odkaz } from '@core/models/odkaz';

@State<AppModel>({
  name: 'app',
  defaults: {
    messages: [],
    bezpecnostniMetodyOdkazy: [],
    spodniOdkazy: [],
    env: null,
    uzivatel: null
  }
})
@Injectable()
export class AppState {
  private static readonly ENV_FILENAME = 'env.json';

  constructor(private http: HttpClientService) {
  }

  @Selector()
  static getMessages(routeName: string) {
    return createSelector([AppState], (state: { app: AppModel }) => {
      return state.app.messages.filter(m => m.routeName === routeName);
    });
  }

  @Selector()
  static getBezpecnostniMetodyOdkazy(state: AppModel) {
    return state.bezpecnostniMetodyOdkazy;
  }

  @Selector()
  static getSpodniOdkazy(state: AppModel) {
    return state.spodniOdkazy;
  }

  @Selector()
  static getUzivatel(state: AppModel) {
    return state.uzivatel;
  }

  @Selector()
  static getEnv(state: AppModel) {
    return state.env;
  }

  @Action(AddMessage)
  addMessage({ getState, patchState }: StateContext<AppModel>, { message }: AddMessage): void {
    const state = getState();
    patchState({ messages: [...state.messages, message]});
  }

  @Action(ClearMessage)
  clearMessage({ getState, patchState }: StateContext<AppModel>, { routeName }: ClearMessage): void {
    const state = getState();
    patchState({ messages: [...state.messages.filter(m => m.routeName !== routeName)]});
  }

  @Action(InitializeOdkazy)
  async initializeOdkazy({ getState, patchState }: StateContext<AppModel>): Promise<void> {
    const state = getState();
    const resp = await firstValueFrom(this.http.get<OdkazyResponse>(state.env.odkazyUrl));
    const odkazy = this.doplnBezpecnostniMetody(resp.bezpecnostniMetodyOdkazy)
      .filter(odkaz => odkaz.url.includes(location.host));
    patchState({ spodniOdkazy: resp.spodniOdkazy, bezpecnostniMetodyOdkazy: odkazy });
  }

  private doplnBezpecnostniMetody(odkazy: Odkaz[]): ExtendedOdkaz[] {
    return odkazy.map(o => {
      return { ...o, ...doplnujiciNastaveniOdkazu.find(dno => dno.odkazName.toUpperCase() === o.text.toUpperCase()) };
    });
  }

  @Action(InitializeEnv)
  async initializeEnv({ patchState }: StateContext<AppModel>): Promise<void> {
    const env = await firstValueFrom(this.http.get<EnvConfig>(AppState.ENV_FILENAME));
    patchState({ env });
  }

  @Action(InitializeUzivatel)
  async initializeUzivatel({ getState, patchState }: StateContext<AppModel>): Promise<void> {
    const state = getState();
    const uzivatel = await firstValueFrom(this.http.get<Uzivatel>(state.env.uzivatelUrl));
    patchState({ uzivatel });
  }

  @Action(InitializeApp)
  async initializeApp({ dispatch }: StateContext<AppModel>): Promise<void> {
    await firstValueFrom(dispatch(new InitializeEnv()));
    await firstValueFrom(forkJoin([
      dispatch(new InitializeUzivatel()),
      dispatch(new InitializeOdkazy())
    ]));
  }

  @Action(ResetUzivatel)
  resetUzivatel({ patchState }: StateContext<AppModel>): void {
    patchState({ uzivatel: null });
  }
}

const doplnujiciNastaveniOdkazu: DoplnujiciNastaveniOdkazu[] = [
  { odkazName: 'George v mobilu', icon: 'ico-george-logo-xl', primary: false, id: 'GEORGE_ID' },
  { odkazName: 'Heslo+SMS', icon: 'ico-sms-code-xl', primary: false, id: 'PASSWORD_AND_SMS' },
  { odkazName: 'Heslo', icon: 'ico-pin-code-xl', primary: true, id: 'PASSWORD' },
  { odkazName: 'Interně ČS', icon: 'ico-sparkasse-xl', primary: true, id: 'INTERNAL' },
  { odkazName: 'Interně Leasing ČS', icon: 'ico-pfm-car-xl', primary: false, id: 'INTERNAL_LEASING' },
  { odkazName: 'Interně SSČS', icon: 'ico-burinka-xl', primary: false, id: 'INTERNAL_BURINKA' }
];

import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom, throwError } from 'rxjs';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { MessageType } from '@core/states/app/app.model';
import { Store } from '@ngxs/store';
import { parseLastRoute } from '@shared/utils/global.util';
import { AddMessage } from '@core/states/app/app.action';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private store: Store,
              private router: Router) {
  }

  async handleError(error: HttpErrorResponse, options?: ErrorHandlerOptions): Promise<never> {
    let defaultError = true;
    const errorBody = error.error as Errors;
    if (errorBody) {
      defaultError = this.handleFormError(errorBody.fieldErrors, options?.form, defaultError);
      defaultError = this.handleMessageError(errorBody.message, defaultError);
    }
    if (defaultError) {
      //this.router.navigate()
      this.error(`Nepodařilo se provést požadovanou akci (${error.status})`);
    }

    return firstValueFrom(throwError(() => error));
  }

  private handleMessageError(error: string | string[], defaultError: boolean): boolean {
    if (error instanceof Array) {
      error.forEach(m => this.error(m));
      return false;
    } else if (error) {
      this.error(error);
      return false;
    }
    return defaultError;
  }

  private handleFormError(errors: FieldError[], form: FormGroup | UntypedFormGroup, defaultError: boolean): boolean {
    if (errors && form) {
      errors.forEach(fieldError => {
        const fc: AbstractControl = this.getFormControl(fieldError.attribut, form);
        if (fc) {
          fc.setErrors({ custom: fieldError.message });
          fc.markAsDirty();
        }
      });
      return false;
    }
    return defaultError;
  }

  private getFormControl(path: string, form: FormGroup | UntypedFormGroup): AbstractControl {
    path = path.replace(/\[/g, '.');
    path = path.replace(/]/g, '');
    return form.get(path);
  }

  private error(message: string): void {
    const lastRoute = parseLastRoute(this.router.url);
    this.store.dispatch(new AddMessage({ text: message, type: MessageType.error, routeName: lastRoute }));
  }
}

export interface ErrorHandlerOptions {
  form?: UntypedFormGroup | FormGroup;
}

export interface Errors {
  fieldErrors: FieldError[];
  error: string | string[];
  message: string | string[];
}

export interface FieldError {
  message: string;
  attribut: string;
}

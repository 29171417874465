import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { routeNames } from '@route-names';
import { isAuthorizedGuard } from '@core/guards/is-authorized/is-authorized.guard';
import { redirectGuard } from '@core/guards/redirect/redirect.guard';
import { AppComponent } from 'src/app/app.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [redirectGuard],
    component: AppComponent // is not used here (guard blocks it), required for route definition
  },
  {
    path: routeNames.BEZPECNOSTNI_ROZCESTNIK,
    loadChildren: async () => (await import('./features/bezpecnostni-rozcestnik/bezpecnostni-rozcestnik.module')).BezpecnostniRozcestnikModule
  },
  {
    path: routeNames.SPRAVA_UZIVATELE,
    canMatch: [isAuthorizedGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: async () => (await import('./features/sprava-uzivatele/sprava-uzivatele.module')).SpravaUzivateleModule
  },
  {
    path: routeNames.FAQ,
    loadComponent: async () => (await import('./features/faq/faq.component')).FaqComponent
  },
  {
    path: routeNames.KONTAKTY,
    canMatch: [isAuthorizedGuard],
    loadComponent: async () => (await import('./features/kontakty/kontakty.component')).KontaktyComponent
  },
  {
    path: '**',
    redirectTo: `${routeNames.BEZPECNOSTNI_ROZCESTNIK}/${routeNames.ERROR}`
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
